import React, { ReactElement } from "react"
import Header from '../components/header'
import Footer from "../components/footer";

function NotFound(): ReactElement {
    return (
        <>
            <Header page={'About'} />
            <div className="w-full flex flex-col items-center justify-center">
                <img className="w-full md:w-1/2 py-20 px-10" src={'/not_found.svg'} alt={'page-not-found'} />
                <p className="text-2xl font-bold">Page Not Found</p>
            </div>
            <Footer />
        </>
    )
}

export default NotFound
